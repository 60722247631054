import { darken, getContrastRatio, lighten } from "@material-ui/core"
import createTheme, { ThemeOptions } from "@material-ui/core/styles/createTheme"
import { Typography } from "@material-ui/core/styles/createTypography"
import { CSSProperties } from "@material-ui/core/styles/withStyles"
import React from "react"

// DEPRECATED: Use the new theme instead
export type ColorStylesV2 = {
  900: string
  800: string
  700: string
  600: string
  500: string
  400: string
  300: string
  200: string
  100: string
  50: string
}

export type ColorPaletteStyles = {
  700: string
  600: string
  500: string
  400: string
  300: string
  200: string
  100: string
}

export type GroovyColor =
  | "orange"
  | "yellow"
  | "green"
  | "cyan"
  | "blue"
  | "purple"
  | "pink"
  | "red"
  | "grey"
  | "onDark"
  | "neutral"

export type GroovyGradient =
  | "blue"
  | "copilot"
  | "copilotDark"
  | "aiFillDark"
  | "onboarding"
  | "newCommunityOnboarding"
  | "purple"
  | "cyan"
  | "orange"
  | "yellow"
  | "gold"
  | "silver"
  | "bronze"
  | "black"
  | "blackReversed"

export type TextVariant = HeaderVariant | BodyVariant

export type HeaderVariant =
  | "heading-xxl"
  | "heading-xl"
  | "heading-lg"
  | "heading-md"
  | "heading-sm"
  | "heading-xs"

export type BodyVariant = "body-lg" | "body-md" | "body-sm" | "body-xs"

export type WeightModifier = 500 | 600 | 700

export type TransformModifier = "uppercase"

export type GroovyThemeConstant =
  | "icon"
  | "stroke"
  | "divider"
  | "borderSmall"
  | "borderLarge"
  | "borderSmallDarkOnly"
  | "borderLargeDarkOnly"
  | "tableBorder"

export type TextVariantWithModifiers =
  | HeaderVariant
  | `${HeaderVariant}-${WeightModifier}`
  | BodyVariant
  | `${BodyVariant}-${WeightModifier}`
  | `${BodyVariant}-${TransformModifier}`
  | `${BodyVariant}-${WeightModifier}-${TransformModifier}`

export type PaletteTextType = {
  primary: string
  secondary: string
  danger: string
  disabled: string
}

export type Spacing = {
  padding?: number
  margin?: number
  marginTop?: number
  marginBottom?: number
  marginLeft?: number
  marginRight?: number
  paddingTop?: number
  paddingBottom?: number
  paddingLeft?: number
  paddingRight?: number
}
export type AIGradient =
  | "coPilotOutline"
  | "coPilotFill"
  | "aiLight"
  | "aiDark"
  | "bluePurpleLight"
  | "bluePurpleDark"
  | "bluePurple02"
  | "bluePurple03"

type AIColors = "blueDark" | "blue"

type BoxShadowColors = {
  insideCard: string
  boxShadow: string
  raisedBoxShadow: string
  xs: string
}

declare module "@material-ui/core/styles/createTheme" {
  interface Theme {
    typography: Typography
    measure: {
      borderRadius: {
        small: React.CSSProperties["borderRadius"]
        default: React.CSSProperties["borderRadius"]
        smedium: React.CSSProperties["borderRadius"]
        medium: React.CSSProperties["borderRadius"]
        big: React.CSSProperties["borderRadius"]
        large: React.CSSProperties["borderRadius"]
        xl: React.CSSProperties["borderRadius"]
        xxl: React.CSSProperties["borderRadius"]
      }
      page: {
        sideBarWidth: number
        sideBarContentWidth: number
        communitySwitcherWidth: number
        contentMaxWidth: number
      }
      modalMaxWidth: {
        mobile: React.CSSProperties["maxWidth"]
        small: number
        medium: number
        large: number
      }
      drawerMaxWidth: {
        xs: number
        small: number
        medium: number
        large: number
        xl: number
        xxl: number
      }
      coverPhotoHeightToWidth: React.CSSProperties["aspectRatio"]
    }
  }
  interface ThemeOptions {
    measure: {
      borderRadius: {
        small: React.CSSProperties["borderRadius"]
        default: React.CSSProperties["borderRadius"]
        smedium: React.CSSProperties["borderRadius"]
        medium: React.CSSProperties["borderRadius"]
        big: React.CSSProperties["borderRadius"]
        large: React.CSSProperties["borderRadius"]
        xl: React.CSSProperties["borderRadius"]
        xxl: React.CSSProperties["borderRadius"]
      }
      page: {
        sideBarWidth: number
        sideBarContentWidth: number
        communitySwitcherWidth: number
        contentMaxWidth: number
      }
      modalMaxWidth: {
        mobile: React.CSSProperties["maxWidth"]
        small: number
        medium: number
        large: number
      }
      drawerMaxWidth: {
        xs: number
        small: number
        medium: number
        large: number
        xl: number
        xxl: number
      }
      coverPhotoHeightToWidth: React.CSSProperties["aspectRatio"]
    }
  }
}

declare module "@material-ui/core/styles/createTypography" {
  interface Typography extends Record<TextVariant, CSSProperties> {
    modifiers: {
      fontWeight: Record<WeightModifier, CSSProperties>
      textTransform: Record<TransformModifier, CSSProperties>
    }
  }
  // allow configuration using `createMuiTheme`
  interface TypographyOptions extends Partial<Record<TextVariant, CSSProperties>> {
    modifiers: {
      fontWeight: Record<WeightModifier, CSSProperties>
      textTransform: Record<TransformModifier, CSSProperties>
    }
  }
}

declare module "@material-ui/core/styles/zIndex" {
  interface ZIndex {
    base: number
    overlay: number
    raise1: number
    raise2: number
    raise3: number
    pageHeader: number
    drawer: number
    footer: number
    chatBar: number
    floatingBar: number
    modal: number
    adminDrawer: number
    confetti: number
  }
}
declare module "@material-ui/core/styles/createPalette" {
  interface TypeBackground {
    hover: string
    light: string
    nav: string
  }
  interface PaletteColor {
    hover?: string
  }

  interface TypeText {
    primary: string
    secondary: string
    danger: string
  }

  interface SimplePaletteColorOptions {
    hover?: string
  }

  interface Palette {
    yellow: SimplePaletteColorOptions
    green: SimplePaletteColorOptions
    cyan: SimplePaletteColorOptions
    orange: SimplePaletteColorOptions
    groovyDepths: BoxShadowColors
    constants: Record<GroovyThemeConstant, string>
    groovy: Record<GroovyColor, ColorPaletteStyles>
    gradient: Record<GroovyGradient, string>
    aiGradient: Record<AIGradient, string>
    aiColors: Record<AIColors, string>
  }

  interface PaletteOptions {
    yellow: SimplePaletteColorOptions
    green: SimplePaletteColorOptions
    cyan: SimplePaletteColorOptions
    orange: SimplePaletteColorOptions
    groovyDepths: BoxShadowColors
    constants: Record<GroovyThemeConstant, string>
    groovy: Record<GroovyColor, ColorPaletteStyles>
    gradient: Record<GroovyGradient, string>
    aiGradient: Record<AIGradient, string>
    aiColors: Record<AIColors, string>
  }
}

export const DEFAULT_LIGHT_THEME = {
  primaryColor: "#4467F6",
  primaryContrastColor: "#FFFFFF",
  backgroundColor: "#FCFCFC",
  cardBackgroundColor: "#FFFFFF",
  navBackgroundColor: "#F4F5F7",
  kind: "light" as const,
}

export const DEFAULT_DARK_THEME = {
  primaryColor: "#4467F6",
  primaryContrastColor: "#FFFFFF",
  backgroundColor: "#262628",
  cardBackgroundColor: "#18181B",
  navBackgroundColor: "#27272A",
  kind: "dark" as const,
}

const defaultTheme = createTheme()

export function createAppTheme(options: Partial<ThemeOptions> = {}) {
  return createTheme({
    mixins: {
      toolbar: {
        minHeight: 72,
      },
      gutters: (styles) => ({
        // same horizontal padding as PageContent component
        padding: defaultTheme.spacing(1, 4),
        [defaultTheme.breakpoints.down("sm")]: {
          padding: defaultTheme.spacing(1, 3),
        },
        [defaultTheme.breakpoints.down("xs")]: {
          padding: defaultTheme.spacing(1),
        },
        ...styles,
      }),
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          "*, *:before, *:after": {
            boxSizing: "border-box",
            margin: 0,
            padding: 0,
          },
          "*": {
            fontFamily: [
              "Figtree",
              "Helvetica Neue",
              "Helvetica",
              "Arial",
              "sans-serif",
            ].join(","),
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
            textTransform: "initial",
          },
          a: {
            textDecoration: "none",

            transition: "color 0.4s ease",

            "&:focus": {
              outline: "none",
            },
          },
          "h1, h2, h3, h4, h5, h6": {
            fontWeight: 400,
          },
          // The visx package sets a custom width that will overflow the
          // parent container, causing XY scroll bars to appear. This
          // overrides that behavior by setting the width to 100%.
          ".visx-tooltip.visx-tooltip-glyph > svg": {
            width: "100%",
          },
        },
      },
      MuiToolbar: {
        regular: {
          minHeight: 72,
          "@media (min-width:0px) and (orientation: landscape)": {
            minHeight: 72,
          },
          "@media (min-width:600px)": {
            minHeight: 72,
          },
        },
        gutters: {
          // same horizontal padding as PageContent component
          padding: defaultTheme.spacing(1, 4),
          [defaultTheme.breakpoints.down("sm")]: {
            padding: defaultTheme.spacing(1, 3),
          },
          [defaultTheme.breakpoints.down("xs")]: {
            padding: defaultTheme.spacing(1),
          },
        },
      },
    },
    typography: {
      // Typography system https://www.figma.com/file/WTaQmtUBbkv6t1WS3umNTw/DISCO-Design-System?node-id=124%3A6274
      fontFamily: ["Figtree", "Helvetica Neue", "Helvetica", "Arial", "sans-serif"].join(
        ","
      ),
      "heading-xxl": {
        fontSize: "48px",
        lineHeight: "48px",
        fontWeight: 700,
        fontStyle: "normal",
        [defaultTheme.breakpoints.down("sm")]: {
          fontSize: "36px",
          lineHeight: "40px",
        },
      },
      "heading-xl": {
        fontSize: "36px",
        lineHeight: "42px",
        fontWeight: 700,
        fontStyle: "normal",
        [defaultTheme.breakpoints.down("sm")]: {
          fontSize: "30px",
          lineHeight: "36px",
        },
      },
      "heading-lg": {
        fontSize: "30px",
        lineHeight: "36px",
        fontWeight: 700,
        fontStyle: "normal",
        [defaultTheme.breakpoints.down("sm")]: {
          fontSize: "24px",
          lineHeight: "32px",
        },
      },
      "heading-md": {
        fontSize: "24px",
        lineHeight: "32px",
        fontWeight: 700,
        fontStyle: "normal",
        [defaultTheme.breakpoints.down("sm")]: {
          fontSize: "20px",
          lineHeight: "28px",
        },
      },
      "heading-sm": {
        fontSize: "20px",
        lineHeight: "28px",
        fontWeight: 700,
        fontStyle: "normal",
        [defaultTheme.breakpoints.down("sm")]: {
          fontSize: "16px",
          lineHeight: "24px",
        },
      },
      "heading-xs": {
        fontSize: "16px",
        lineHeight: "24px",
        [defaultTheme.breakpoints.down("sm")]: {
          fontSize: "14px",
          lineHeight: "20px",
        },
      },
      "body-lg": {
        fontWeight: 400,
        fontStyle: "normal",
        fontSize: "18px",
        lineHeight: "28px",
        textTransform: "initial",
      },
      "body-md": {
        fontWeight: 400,
        fontStyle: "normal",
        fontSize: "16px",
        lineHeight: "24px",
        textTransform: "initial",
      },
      "body-sm": {
        fontWeight: 400,
        fontStyle: "normal",
        fontSize: "14px",
        lineHeight: "20px",
        textTransform: "initial",
      },
      "body-xs": {
        fontWeight: 400,
        fontStyle: "normal",
        fontSize: "12px",
        lineHeight: "16px",
        textTransform: "initial",
      },
      modifiers: {
        fontWeight: {
          500: {
            fontWeight: 500,
          },
          600: {
            fontWeight: 600,
          },
          700: {
            fontWeight: 700,
          },
        },
        textTransform: {
          uppercase: {
            textTransform: "uppercase",
          },
        },
      },
    },
    palette: {
      // darken default paper color
      divider: darken("#FFFFFF", 0.1),
      constants: {
        icon: "#A1A1AA",
        stroke: "#EBEBEF",
        divider: "#EBEBEF",
        borderSmall: `1px solid #EBEBEF`,
        borderLarge: `2px solid #EBEBEF`,
        borderSmallDarkOnly: "none",
        borderLargeDarkOnly: "none",
        tableBorder: `2px solid #F4F4F5`,
      },
      aiGradient: {
        coPilotOutline: `linear-gradient(88deg, #4467F6 17.39%, #FF825C 87.51%)`,
        coPilotFill: `linear-gradient(244deg, #FFE8E1 16.87%, #DDE3FD 77.59%)`,

        aiLight: `linear-gradient(245deg, #F4E7F9 0.06%, #FFFFFF 48.49%, #FBFBFE 55.1%, #E1E6FD 100.06%)`,
        aiDark: `linear-gradient(88deg, #4B44F6 17.39%, #966BDB 87.51%)`,

        bluePurpleLight: `linear-gradient(245.12deg, rgba(185, 156, 231, 0.16) 0.06%, rgba(255, 255, 255, 0.16) 48.49%, rgba(231, 236, 254, 0.16) 55.1%, rgba(68, 103, 246, 0.16) 100.06%)`,
        bluePurpleDark: `linear-gradient(245.12deg, #27315C 0%, #1C1B1F 52%, #2F2244 100%)`,
        bluePurple02: `linear-gradient(87.6deg, #8AA0F9 17.39%, #B99CEF 87.51%)`,
        bluePurple03: `linear-gradient(87.6deg, #4664FF 17.39%, #966CDB 87.51%)`,
      },
      aiColors: {
        blueDark: "#272E49",
        blue: "#4664FF",
      },

      gradient: {
        blue: `linear-gradient(90deg, #4467F6 0%, #8AA0F9 99.96%)`,
        purple: "linear-gradient(51.64deg, #733ACF -5.98%, #B99CE7 106.81%)",
        cyan: "linear-gradient(51.49deg, #41D9F1 0.05%, #7AE5F5 100.08%)",
        orange: "linear-gradient(51.49deg, #FF825C 0.05%, #FFB199 100.08%)",
        yellow: "linear-gradient(51.49deg, #FFDC5C 0.05%, #FFE999 100.08%)",
        gold: "linear-gradient(51deg, #FF975C 0.05%, #FFDF6B 100.08%)",
        silver: "linear-gradient(51deg, #A1A1AA 0.05%, #E4E4E7 100.08%)",
        bronze: "linear-gradient(51deg, #B86F46 0.05%, #EC8F72 100.08%)",
        copilot:
          "linear-gradient(88.88deg, rgba(68, 103, 246, 0.25) 5.31%, rgba(255, 133, 95, 0.25) 100%), #FFFFFF",
        copilotDark:
          "linear-gradient(88.88deg, rgba(70, 100, 255, 1) 5.31%, rgba(255, 133, 95, 1) 100%), #FFFFFF",
        aiFillDark: "linear-gradient(88deg, #4B44F6 17.39%, #966BDB 87.51%)",
        onboarding:
          "linear-gradient(49.08deg, #F4F4F5 4.71%, #E9ECFC 68.96%, #E3E8FF 71.2%, #E5E9FE 71.2%)",
        newCommunityOnboarding:
          "linear-gradient(230.24deg, rgba(0, 0, 0, 0) 38.66%, rgba(65, 217, 241, 0.6) 93.12%),linear-gradient(145.28deg, rgba(0, 0, 0, 0) 13.96%, rgba(70, 100, 255, 1) 92.53%), linear-gradient(51.49deg, rgba(0, 0, 0, 1) 0.05%, rgba(36, 36, 41, 1) 100.08%)",
        black: `linear-gradient(275.71deg, #3C3C43 -17.86%, #2A2A2F 15.92%, #18181B 86.05%)`,
        blackReversed: `linear-gradient(51.49deg, #3C3C43 0.05%, #18181B 100.08%);`,
      },
      primary: {
        main: "#4467F6", // blue.400
        light: "#D0D9FD", // blue.100
        dark: "#092EC3", // blue.700
        contrastText: "#FFFFFF", // white
        hover: lighten("#4467F6", 0.1),
      },
      yellow: {
        dark: "#634D03",
        main: "#52E0AC",
        light: "#E9FAF4",
      },
      green: {
        dark: "#0F4330",
        main: "#52E0AC",
        light: "#E9FAF4",
      },
      cyan: {
        dark: "#03464F",
        main: "#41D9F1",
        light: "#D9F7FC",
      },
      orange: {
        dark: "#F53900",
        main: "#FF825C",
        light: "#FEF1EA",
      },
      warning: {
        dark: "#524000", // yellow[700]
        main: "#FACC15", // yellow[500]
        light: "#FFF9E7", // yellow[50]
      },
      error: {
        dark: "#DC2626",
        main: "#F25454", // red[500]
        light: "#FEECEC", // red[50]
      },
      success: {
        dark: "#0F4330", // green[700]
        main: "#52E0AC", // green[500]
        light: "#DCF9EE", // green[50]
      },
      text: {
        primary: "#000D25", // grey[900]
        secondary: "#52525B", // grey[600]
        danger: "#CC3748",
      },
      background: {
        default: DEFAULT_LIGHT_THEME.backgroundColor, // grey[100]
        paper: "#FFFFFF",
        hover: darken("#FFFFFF", 0.1),
        light: DEFAULT_LIGHT_THEME.backgroundColor, // grey[100]
        nav: DEFAULT_LIGHT_THEME.navBackgroundColor,
      },
      groovy: {
        neutral: {
          700: "#18181B",
          600: "#27272A",
          500: "#52525B",
          400: "#A1A1AA",
          300: "#D4D4D8",
          200: "#EBEBEF",
          100: "#F8F8F8",
        },
        onDark: {
          700: "#000000",
          600: "#1B1B1D",
          500: "#27272A",
          400: "#35353D",
          300: "#717179",
          200: "#A1A1AA",
          100: "#F4F4F4",
        },
        grey: {
          700: "#18181B",
          600: "#27272A",
          500: "#52525B",
          400: "#A1A1AA",
          300: "#D4D4D8",
          200: "#EBEBEF",
          100: "#F8F8F8",
        },
        blue: {
          // #272E49
          700: "#051761",
          600: "#092EC3",
          500: "#1542F4",
          400: "#4467F6",
          300: "#8AA0F9",
          200: "#C5D0FC",
          100: "#ECEFFE",
        },
        red: {
          700: "#710A0A",
          600: "#BE0E0E",
          500: "#EE1B1B",
          400: "#F25454",
          300: "#F68D8D",
          200: "#FCD9D9",
          100: "#FEECEC",
        },
        green: {
          700: "#0F4330",
          600: "#1C9C6D",
          500: "#25D091",
          400: "#52E0AC",
          300: "#86EAC5",
          200: "#BAF3DE",
          100: "#DCF9EE",
        },
        yellow: {
          700: "#524000",
          600: "#E0B000",
          500: "#FFCE1F",
          400: "#FFDC5C",
          300: "#FFE999",
          200: "#FFF2C2",
          100: "#FFF6D6",
        },
        purple: {
          700: "#361C5F",
          600: "#5728A4",
          500: "#733ACF",
          400: "#966BDB",
          300: "#B99CE7",
          200: "#DCCEF3",
          100: "#F3EFFB",
        },
        orange: {
          700: "#720909",
          600: "#E03400",
          500: "#FF531F",
          400: "#FF825C",
          300: "#FFB199",
          200: "#FFE0D6",
          100: "#FFEFEB",
        },
        cyan: {
          700: "#03464F",
          600: "#0D96AB",
          500: "#11C8E4",
          400: "#41D9F1",
          300: "#7AE5F5",
          200: "#B3F0F9",
          100: "#D9F7FC",
        },
        pink: {
          700: "#600643",
          600: "#D40C91",
          500: "#F32BB0",
          400: "#F665C6",
          300: "#F99FDB",
          200: "#FDD8F1",
          100: "#FEECF8",
        },
      },
      /** see Groovy > Tokens > Depths - https://www.figma.com/file/WTaQmtUBbkv6t1WS3umNTw/Groovy-%2F-Product?node-id=3853%3A22730 */
      groovyDepths: {
        /** When to use: Inner cards in a default state within being shown on top of a Flat white card. */
        insideCard: "0px 1px 4px rgba(46, 64, 88, 0.15);",
        /** When to use: Dropdowns and hover states where the element is raised over top of other items. */
        boxShadow:
          "0px 2px 4px rgba(46, 64, 88, 0.04), 0px 6px 18px rgba(46, 64, 88, 0.08);",
        /** When to use: Raised cards like modals where the card is being shown over an entire page of content, usually with a overlay. */
        raisedBoxShadow:
          "0px 4px 8px rgba(46, 64, 88, 0.08), 0px 16px 32px rgba(46, 64, 88, 0.1)",
        /** When to use: Dashboard blocks and other cards that sit on the customizable background color. */
        xs: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
      },
    },
    props: {
      MuiButtonBase: {
        disableRipple: true,
        disableTouchRipple: true,
      },
    },
    measure: {
      borderRadius: {
        small: 2,
        default: 4,
        smedium: 6,
        medium: 8,
        big: 12,
        large: 16,
        xl: 20,
        xxl: 24,
      },
      page: {
        /** looking for app bar / header / toolbar height? - use theme.mixins.toolbar.minHeight. See the `overrides` key on this theme object for reference */
        sideBarWidth: 296,
        sideBarContentWidth: 270,
        communitySwitcherWidth: 72,
        // mui default lg breakpoint width - https://v4.mui.com/customization/breakpoints/#default-breakpoints
        contentMaxWidth: 1280,
      },
      modalMaxWidth: {
        mobile: "95vw",
        small: 400,
        medium: 540,
        large: 720,
      },
      drawerMaxWidth: {
        xs: 280,
        small: 480,
        medium: 600,
        large: 720,
        // same as contentMaxWidth
        xl: 1280,
        xxl: 1400,
      },
      coverPhotoHeightToWidth: "37.5%",
    },
    zIndex: {
      /** for local (not global) cases where one element needs to be on top of another */
      raise1: 1,
      raise2: 2,
      raise3: 3,
      // Global zIndex values
      base: 4,
      overlay: 5,
      pageHeader: 6, // --page-header-z-index
      footer: 6,
      chatBar: 7,
      adminDrawer: 8,
      drawer: 9,
      floatingBar: 9,
      modal: 10,
      confetti: 11,
    },
    ...options,
  })
}

export const appTheme = createAppTheme()

export function createComputedTheme(
  theme:
    | {
        cardBackgroundColor: string | null
        primaryColor: string | null
        primaryContrastColor: string | null
        backgroundColor: string | null
        navBackgroundColor: string | null
      }
    | undefined
    | null
) {
  const { primaryContrastColor, backgroundColor, navBackgroundColor } =
    theme ?? DEFAULT_LIGHT_THEME

  const paperColor = theme?.cardBackgroundColor ?? DEFAULT_LIGHT_THEME.cardBackgroundColor
  const primaryColor = theme?.primaryColor ?? DEFAULT_LIGHT_THEME.primaryColor
  const isDark = getContrastRatio(appTheme.palette.common.white, paperColor) > 1.5

  return createTheme({
    ...appTheme,
    palette: {
      ...appTheme.palette,
      type: isDark ? "dark" : "light",
      primary: {
        main: primaryColor,
        light: lighten(primaryColor, 0.9),
        hover: lighten(primaryColor, 0.1),
        contrastText: primaryContrastColor || "#ffffff",
      },
      background: {
        default: backgroundColor || appTheme.palette.background.default,
        paper: paperColor,
        nav: navBackgroundColor || appTheme.palette.background.nav,
      },
      constants: isDark
        ? {
            icon: appTheme.palette.groovy.onDark[200],
            stroke: appTheme.palette.groovy.onDark[300],
            divider: appTheme.palette.groovy.onDark[400],
            borderSmall: `1px solid ${appTheme.palette.groovy.onDark[400]}`,
            borderLarge: `2px solid ${appTheme.palette.groovy.onDark[400]}`,
            borderSmallDarkOnly: `1px solid ${appTheme.palette.groovy.onDark[400]}`,
            borderLargeDarkOnly: `2px solid ${appTheme.palette.groovy.onDark[400]}`,
            tableBorder: `2px solid ${appTheme.palette.groovy.onDark[400]}`,
          }
        : appTheme.palette.constants,
      groovy: {
        ...appTheme.palette.groovy,
        neutral: isDark
          ? {
              100: appTheme.palette.groovy.onDark[700],
              200: appTheme.palette.groovy.onDark[600],
              300: appTheme.palette.groovy.onDark[500],
              400: appTheme.palette.groovy.onDark[400],
              500: appTheme.palette.groovy.onDark[300],
              600: appTheme.palette.groovy.onDark[200],
              700: appTheme.palette.groovy.onDark[100],
            }
          : appTheme.palette.groovy.neutral,
        grey: isDark ? appTheme.palette.groovy.onDark : appTheme.palette.groovy.grey,
      },
      text: {
        primary: isDark
          ? appTheme.palette.groovy.onDark[100]
          : appTheme.palette.text.primary,
        secondary: isDark
          ? appTheme.palette.groovy.onDark[200]
          : appTheme.palette.text.secondary,
        danger: appTheme.palette.text.danger,
      },
      groovyDepths: {
        insideCard: isDark
          ? `0px 1px 4px 0px ${darken(paperColor, 0.55)}`
          : `0px 1px 4px 0px ${darken(paperColor, 0.15)}`,
        boxShadow:
          paperColor === DEFAULT_LIGHT_THEME.cardBackgroundColor
            ? "rgb(46 64 88 / 4%) 0px 2px 4px, rgb(46 64 88 / 8%) 0px 6px 18px"
            : isDark
            ? `0px 2px 4px ${darken(paperColor, 0.44)}, 0px 6px 18px ${darken(
                paperColor,
                0.48
              )}`
            : `0px 2px 4px ${darken(paperColor, 0.04)}, 0px 6px 18px ${darken(
                paperColor,
                0.08
              )}`,
        raisedBoxShadow:
          paperColor === DEFAULT_LIGHT_THEME.cardBackgroundColor
            ? "0px 4px 8px rgba(46, 64, 88, 0.08), 0px 16px 32px rgba(46, 64, 88, 0.1)"
            : isDark
            ? `0px 4px 8px ${darken(paperColor, 0.53)}, 0px 16px 32px ${darken(
                paperColor,
                0.55
              )}`
            : `0px 4px 8px ${darken(paperColor, 0.08)}, 0px 16px 32px ${darken(
                paperColor,
                0.1
              )}`,
        xs: isDark ? "none" : "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
      },
      divider: isDark ? lighten(paperColor, 0.1) : darken(paperColor, 0.1),
    },
  })
}
